import React from "react";
import Layout from "../components/Layout";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function Home({ isAuth, isLoading, history }) {
  if (!isLoading && isAuth) {
    history.push("/dashboard");
  }

  return (
    <Layout>
      <section className="landing">
        <div className="container">
          <Row justify="center" gutter={[0, 32]}>
            <Col xs={24} sm={24} md={24} lg={12} className="text-center">
              <h2 className="text-white">
                Department of the Environment Fishing Licences
              </h2>
              <p className="text-white landing-para">
                Temporary Recreational Fishing Licences for Non-Residents (Sea)
              </p>
              <h5 className="text-white" style={{ fontSize: "0.8rem" }}>
                To use this application you must be over 14 years old
              </h5>
      
       <h5 style={{ fontSize: "1.4rem", color: "black" }}>
<u>
<b>Temporary fishing licences are currently not being issued by the Department. We apologise for any inconvenience this may cause.</b>
<br/><br/>
<b>El Departamento actualmente no está emitiendo licencias de pesca recreativas (temporal). Pedimos disculpas por cualquier inconveniente que esto pueda causar.</b>
        </u> </h5>
<br /><br />
              
<h5 style={{ fontSize: "1.4rem", color: "black", display: "none" }}>
                Please note that B2 Temporary Recreational – Rod and Line (Non-Residents fishing from a vessel) licence applications will be accepted as from:

                <br /><br /> <b>Thursday 16th May 2024 at 00:00 to<br />Thursday 30th May 2024 at 23:59</b>
                <br /><br />In addition, please note that C2 Temporary Spearfishing Non-Resident licences are not currently being issued.
                <br /><br />For further information or inquiries, please contact the Department at 54089146.
              </h5>
              <Row
                justify="center"
                className="m-0"
                style={{ margin: "2rem 0", display: "none" }}
              >
                <Link to="/login" className="btn">
                  Start Now
                </Link>
              </Row>
            </Col>
          </Row>
          <Row justify="center" style={{  display: "none" }}>
            <Col xs={24} lg={16}>
              <Row align="middle" gutter={[0, 32]}>
                <Col xs={24} lg={8} className="card">
                  <div className="circle">
                    <div className="anticon">1</div>
                  </div>
                </Col>
                <Col xs={24} lg={16}>
                  <h4 className="text-white text-sm-center">Select Licence</h4>
                  <p className="text-white text-sm-center m-0">
                    Enter your place of residence and then select from one of
                    the license types available to you. You may only submit one
                    application at a time.
                  </p>
                </Col>
              </Row>
              <Row gutter={[0, 32]}>
                <Col xs={24} lg={8} className="card">
                  <div className="circle">
                    <div className="anticon">2</div>
                  </div>
                </Col>
                <Col xs={24} lg={16}>
                  <h4 className="text-white text-sm-center">Enter your data</h4>
                  <p className="text-white text-sm-center m-0">
                    Complete all the data required for your licence application.
                    The data required will vary depending on the licence you
                    apply for.
                  </p>
                </Col>
              </Row>
              <Row gutter={[0, 32]}>
                <Col xs={24} lg={8} className="card">
                  <div className="circle">
                    <div className="anticon">3</div>
                  </div>
                </Col>
                <Col xs={24} lg={16}>
                  <h4 className="text-white text-sm-center">
                    Submit your Application
                  </h4>
                  <p className="text-white text-sm-center m-0">
                    Upload any required documents and submit your application
                    online.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.user.loading,
  isAuth: state.user.auth,
});

export default connect(mapStateToProps, null)(Home);
